import React from 'react'

import { DailyStoreStatisticsPage } from '../../components'

class StoreReports extends React.Component {
  render() {
    return <DailyStoreStatisticsPage />
  }
}

export default StoreReports
